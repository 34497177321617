import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { ProposalModuleService } from 'src/app/services/proposal.service';
import { OpportunityModuleService } from 'src/app/services/opportunity-module.service';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
//import { Router } from '@angular/router';
//import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  model1 = {
    left: true,
    middle: false,
  };

  model: NgbDateStruct;
  date: { year: number, month: number };
  @ViewChild('dp') dp: NgbDatepicker;
  time = { hour: 13, minute: 30 };
  time2 = { hour: 13, minute: 30 };

  @Input() id;
  @Input() action;
  @Input() proposalStatus;
  opportunityList = [];
  OpportunityDetails = [];
  CutomerList = [];
  scopeList = [];
  deliveryScheduleList = [];
  serviceList = [];
  areaList = [];
  myDateValue: Date;
  proposalFrm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  pageTitle: string;
  opportunityId: any;
  userList = [];
  opportunityStyle: boolean;
  isFeasibilityAnalysisPaidStyle: boolean;
  isFeasibilityChecked: boolean;
  topologyList = [];
  uomList = [];
  projectTypeList = [];
  loginId: any;
  minDate: { year: number; month: number; day: number; };
  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public ProposalModuleService: ProposalModuleService,
    public OpportunityModuleService: OpportunityModuleService,
    private calendar: NgbCalendar,
    private atp: AmazingTimePickerService, private spinnerService: Ng4LoadingSpinnerService
  ) {
    {
      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
    }
  }

  ngOnInit() {
    this.myDateValue = new Date();
    this.action = this.route.snapshot.paramMap.get("action");
    //alert(this.action);
    this.id = this.route.snapshot.paramMap.get("id");
    this.loginId = localStorage.getItem("userMasterId");
    //this.action = this.action;

    const token_key = localStorage.getItem("token");
    this.ProposalModuleService.getCutomerList().subscribe((e: any) => {
      this.CutomerList = e.data;
    });
    ////////////////////
    this.OpportunityModuleService.getEmployeeList().subscribe((e: any) => {
      this.userList = e.data;
    });

    this.OpportunityModuleService.getAreaList().subscribe((e: any) => {
      this.areaList = e.data;
    });
    this.ProposalModuleService.getServiceList().subscribe((e: any) => {
      this.serviceList = e.data;
    });
    this.OpportunityModuleService.getTopologyList().subscribe((e: any) => {
      this.topologyList = e.data;
    });
    this.OpportunityModuleService.getUOMList().subscribe((e: any) => {
      this.uomList = e.data;
    });
    this.OpportunityModuleService.getProjectTypeList().subscribe((e: any) => {
      this.projectTypeList = e.data;
    });

    if (this.action === "Edit") {
      this.poupuTitle = "Edit Proposal Master";
      this.pageTitle = "Edit Proposal";
      this.opportunityStyle = true;
      this.ProposalModuleService.getProposalbyId(this.id).then(data => {
        console.log("datapatch", data.data.followupNextFollowupDate);
        this.proposalStatus = data.data.proposalStatus;
        // alert("proposalStatus->"+this.proposalStatus);
        // alert("action->"+this.action);
        // projectSchedule = data.data['projectSchedule'];
        this.getScopeList(data.data['projectTypeId']);
        this.getDeliveryScheduleList(data.data['projectTypeId']);
        this.getCustomerContactDetails(data.data['customerId']);

        this.proposalFrm.patchValue(data.data);

        if (data.data.isFeasibilityAnalysisPaid == 1) {
          this.opportunityStyle = true;
          this.isFeasibilityChecked = true;
          this.isFeasibilityAnalysisPaidStyle = true;
          this.proposalFrm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(1);
        } else {
          this.opportunityStyle = false;
          this.isFeasibilityAnalysisPaidStyle = false;
          this.isFeasibilityChecked = false;
          this.proposalFrm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(0);

        }

        this.opportunityId = data.data.opportunityId;
        this.ProposalModuleService.getOpportunity(this.opportunityId).subscribe((e: any) => {
          this.opportunityList = e.data;
        });

        const expectedSigningDate = new Date(data.data.followupNextFollowupDate);
        this.proposalFrm.controls['followupNextFollowupDate'].setValue({ year: expectedSigningDate.getFullYear(), month: expectedSigningDate.getMonth() + 1, day: expectedSigningDate.getDate() });
        this.proposalFrm.controls['nextFollowUpTime'].setValue(data.data.nextFollowUpTime);
        /////////////////////////
        data.data.proposalScopeDefine.forEach((x, index) => {
          const documentArray = this.proposalFrm.controls.scopeOfWork as FormArray;
          documentArray.push(
            this._formBuilder.group({
              scopeOfWorkId: x.id,
              scopeOfWorkType: x.type,
              projectScopeId: x.projectScopeId,
            })
          );
          // this.getScopeDetails(x.machine_type_id,index);
        });
        /////////////////////////
        data.data.proposalProjectSchedule.forEach((x, index) => {
          const documentArray = this.proposalFrm.controls.projectSchedule as FormArray;
          documentArray.push(
            this._formBuilder.group({
              projectScheduleId: x.id,
              deliveryScheduleId: x.projectScheduleId,
              paymentAgainst: x.paymentAgainst,
              projectScheduleDescription: x.description,
              projectScheduleRemark: x.remark,
            })
          );
          // this.getScopeDetails(x.machine_type_id,index);
        });
        /////////////////////////
        data.data.proposalPaymentSchedule.forEach((x, index) => {
          const documentArray = this.proposalFrm.controls.costDetails as FormArray;
          documentArray.push(
            this._formBuilder.group({
              costDetailsId: x.id,
              stageOfWork: x.stageOfWork,
              details: x.details,
              percentage: x.percentage,
              paymentValue: x.paymentValue,
              description: x.description,
            })
          );
          // this.getScopeDetails(x.machine_type_id,index);
        });

        data.data.serviceResult.forEach((x, index) => {
          const documentArray = this.proposalFrm.controls.proposalServiceDetails as FormArray;
          documentArray.push(
            this._formBuilder.group({
              serviceId: x.id,
              sevices: x.serviceId,
            })
          );
        });
      });
    } else if (this.action === "Approval") {
      this.poupuTitle = "Edit Proposal Master";
      this.pageTitle = "Edit Proposal";
      this.opportunityStyle = true;
      this.ProposalModuleService.getProposalbyId(this.id).then(data => {
        console.log("datapatch", data.data.followupNextFollowupDate);
        this.proposalStatus = data.data.proposalStatus;
        // alert("proposalStatus->"+this.proposalStatus);
        // alert("action->"+this.action);
        // projectSchedule = data.data['projectSchedule'];
        this.getScopeList(data.data['projectTypeId']);
        this.getDeliveryScheduleList(data.data['projectTypeId']);
        this.getCustomerContactDetails(data.data['customerId']);


        if (data.data.isFeasibilityAnalysisPaid == 1) {
          this.opportunityStyle = true;
          this.isFeasibilityChecked = true;
          this.isFeasibilityAnalysisPaidStyle = true;
          this.proposalFrm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(1);
          this.proposalFrm.controls['feasibilityCharges'].setValue(0);
        } else {
          this.opportunityStyle = false;
          this.isFeasibilityAnalysisPaidStyle = false;
          this.isFeasibilityChecked = false;
          this.proposalFrm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(0);
          this.proposalFrm.controls['feasibilityCharges'].setValue(0);
        }

        this.opportunityId = data.data.opportunityId;
        this.ProposalModuleService.getOpportunity(this.opportunityId).subscribe((e: any) => {
          this.opportunityList = e.data;
        });

        this.proposalFrm.patchValue(data.data);

        const expectedSigningDate = new Date(data.data.followupNextFollowupDate);
        this.proposalFrm.controls['followupNextFollowupDate'].setValue({ year: expectedSigningDate.getFullYear(), month: expectedSigningDate.getMonth() + 1, day: expectedSigningDate.getDate() });
        this.proposalFrm.controls['nextFollowUpTime'].setValue(data.data.nextFollowUpTime);


        /////////////////////////
        data.data.proposalScopeDefine.forEach((x, index) => {
          const documentArray = this.proposalFrm.controls.scopeOfWork as FormArray;
          documentArray.push(
            this._formBuilder.group({
              scopeOfWorkId: x.id,
              scopeOfWorkType: x.type,
              projectScopeId: x.projectScopeId,
            })
          );
        });
        /////////////////////////
        data.data.proposalProjectSchedule.forEach((x, index) => {
          const documentArray = this.proposalFrm.controls.projectSchedule as FormArray;
          documentArray.push(
            this._formBuilder.group({
              projectScheduleId: x.id,
              deliveryScheduleId: x.projectScheduleId,
              paymentAgainst: x.paymentAgainst,
              projectScheduleDescription: x.description,
              projectScheduleRemark: x.remark,
            })
          );
        });
        /////////////////////////
        data.data.proposalPaymentSchedule.forEach((x, index) => {
          const documentArray = this.proposalFrm.controls.costDetails as FormArray;
          documentArray.push(
            this._formBuilder.group({
              costDetailsId: x.id,
              stageOfWork: x.stageOfWork,
              details: x.details,
              percentage: x.percentage,
              paymentValue: x.paymentValue,
              description: x.description,
            })
          );
        });

        data.data.serviceResult.forEach((x, index) => {
          const documentArray = this.proposalFrm.controls.proposalServiceDetails as FormArray;
          documentArray.push(
            this._formBuilder.group({
              serviceId: x.id,
              sevices: x.serviceId,
            })
          );
        });

      });
    } else if (this.action === "Add") {

      this.opportunityStyle = false;
      this.isFeasibilityAnalysisPaidStyle = true;
      this.isFeasibilityChecked = true;
      this.poupuTitle = "Add Proposal Master";
      this.pageTitle = "Add Proposal";
      /////////////////////
      this.ProposalModuleService.getOpportunity(this.opportunityId).subscribe((e: any) => {
        this.opportunityList = e.data;
      });
    }
    this.proposalFrm = this.createCountryForm();
    this.proposalFrm.controls['proposalVersion'].setValue(0);
    this.proposalFrm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(1);
    this.proposalFrm.controls['feasibilityCharges'].setValue(0);
    if (this.action === "Add" && this.id) {
      this.proposalFrm.controls['opportunityId'].setValue(this.id);
      this.changeOpportunity(this.id);
    }


  }

  createCountryForm(): FormGroup {
    return (this.proposalFrm = this._formBuilder.group({
      opportunityId: ["", Validators.required],
      customerId: ["", Validators.required],
      projectName: ["", Validators.required],
      feasibilityCharges: [""],
      proposalVersion: [""],
      projectCode: ["", Validators.required],
      projectDescription: [""],
      projectAddress: ["", Validators.required],
      latLong: ["", Validators.required],
      siteArea: ["", Validators.required],

      //proposalStatus: ["", Validators.required],
      //projectContactPersonName: ["", Validators.required],
      //projectContactPersonNumber: ["", Validators.required],

      // proposalBasedOn: ["", Validators.required],

      // incharge: ["", Validators.required],

      followupNextFollowupDate: ["", Validators.required],
      followupRemark: ["", Validators.required],
      followupStatus: ["", Validators.required],
      followupAssignTo: ["", Validators.required],
      nextFollowUpTime: ["", Validators.required],
      location: ["", Validators.required],
      landUom: ["", Validators.required],
      typology: ["", Validators.required],
      fsiBuiltUp: ["", Validators.required],
      fsiBuiltUpUom: ["", Validators.required],
      constructionApprox: ["", Validators.required],
      constructionApproxUom: ["", Validators.required],
      projectType: ["", Validators.required],
      isFeasibilityAnalysisPaidCheckbox: [""],
      rate: [""],
      projectCost: [""],
      totalCost: [""],
      projectSchedule: this._formBuilder.array([]),
      scopeOfWork: this._formBuilder.array([]),
      costDetails: this._formBuilder.array([]),
      proposalServiceDetails: this._formBuilder.array([]),
      contactDetails: this._formBuilder.array([]),
    }));
  }

  addContactDetails() {
    const documentArray = this.proposalFrm.controls.contactDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        cotactId: [""],
        contactName: ["", Validators.required],
        contactNumber: ["", Validators.required],
        contactDesignation: ["", Validators.required],
        contactEmailId: ["", Validators.required],
      })
    );
  }

  getContactFormControls() {
    return (this.proposalFrm.get("contactDetails") as FormArray).controls;
  }

  getCustomerContactDetails(id: any) {
    let contactDetails = ((this.proposalFrm.get('contactDetails') as FormArray).length + 1);
    for (let i = 0; i < contactDetails; i++) {
      (this.proposalFrm.get('contactDetails') as FormArray).removeAt(i);
    }
    this.OpportunityModuleService.getCustomerContactDetails(id).then(data => {
      data.data.forEach((x, index) => {
        const documentArray = this.proposalFrm.controls.contactDetails as FormArray;
        const next_followup_date = new Date(x.nextFollowupDate);

        documentArray.push(
          this._formBuilder.group({
            cotactId: x.id,
            contactName: x.name,
            contactNumber: x.number,
            contactDesignation: x.designation,
            contactEmailId: x.emailId,
          })
        );
      });
    });
  }

  open(ev: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      this.proposalFrm.controls['nextFollowUpTime'].setValue(time);
    });
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }
  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }
  get f() {
    return this.proposalFrm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.proposalFrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }

    let proposalServiceDetailsCount = ((this.proposalFrm.get('proposalServiceDetails') as FormArray).length);
    if (proposalServiceDetailsCount === 0) {
      this.toastr.error("Atleast one service is required.", "Error!");
      return;
    }

    let scopeOfWorkTypeCount = ((this.proposalFrm.get('scopeOfWork') as FormArray).length);
    if (scopeOfWorkTypeCount == 0) {
      this.toastr.error("Atleast one project comprehensive scope is required.", "Error!");
      return;
    }

    let projectScheduleCount = ((this.proposalFrm.get('projectSchedule') as FormArray).length);
    if (projectScheduleCount == 0) {
      this.toastr.error("Atleast one project delivery schedule is required.", "Error!");
      return;
    }
    console.log(this.proposalFrm.get('costDetails') as FormArray);
    let costDetailsCount = ((this.proposalFrm.get('costDetails') as FormArray).length);
    if (costDetailsCount == 0) {
      this.toastr.error("Atleast one payment stage details is required.", "Error!");
      return;
    }
    let costDetailsArray = ((this.proposalFrm.get('costDetails') as FormArray).length);
    let totalPercentage = 0;
    for (let i = 0; i < costDetailsArray; i++) {
      let Percentage = this.proposalFrm.controls['costDetails']['controls'][i]['controls']['percentage'].value;
      totalPercentage = totalPercentage + parseFloat(Percentage);

    }

    if (totalPercentage != 100) {
      this.toastr.error("The sum of percentage in payment stage details must be 100.", "Error!");
      return;
    }

    this.ProposalModuleService.createPraposal(this.proposalFrm.value, this.loginId).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/proposal/proposal/proposallist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  UpdateProposal() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.proposalFrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }

    let proposalServiceDetailsCount = ((this.proposalFrm.get('proposalServiceDetails') as FormArray).length);
    if (proposalServiceDetailsCount === 0) {
      this.toastr.error("Atleast one service is required.", "Error!");
      return;
    }

    let scopeOfWorkTypeCount = ((this.proposalFrm.get('scopeOfWork') as FormArray).length);
    if (scopeOfWorkTypeCount == 0) {
      this.toastr.error("Atleast one project comprehensive scope is required.", "Error!");
      return;
    }

    let projectScheduleCount = ((this.proposalFrm.get('projectSchedule') as FormArray).length);
    if (projectScheduleCount == 0) {
      this.toastr.error("Atleast one project delivery schedule is required.", "Error!");
      return;
    }
    console.log(this.proposalFrm.get('costDetails') as FormArray);
    let costDetailsCount = ((this.proposalFrm.get('costDetails') as FormArray).length);
    if (costDetailsCount == 0) {
      this.toastr.error("Atleast one payment stage details is required.", "Error!");
      return;
    }
    let costDetailsArray = ((this.proposalFrm.get('costDetails') as FormArray).length);
    let totalPercentage = 0;
    for (let i = 0; i < costDetailsArray; i++) {
      let Percentage = this.proposalFrm.controls['costDetails']['controls'][i]['controls']['percentage'].value;
      totalPercentage = totalPercentage + parseFloat(Percentage);

    }

    if (totalPercentage != 100) {
      this.toastr.error("The sum of percentage in payment stage details must be 100.", "Error!");
      return;
    }
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.proposalFrm.value.id = this.id;
    if (this.proposalFrm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    this.ProposalModuleService.updatePraposal(this.id, this.proposalFrm.value).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);
        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/proposal/proposal/proposallist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }
  createProposalRevision() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.proposalFrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }

    let proposalServiceDetailsCount = ((this.proposalFrm.get('proposalServiceDetails') as FormArray).length);
    if (proposalServiceDetailsCount === 0) {
      this.toastr.error("Atleast one service is required.", "Error!");
      return;
    }

    let scopeOfWorkTypeCount = ((this.proposalFrm.get('scopeOfWork') as FormArray).length);
    if (scopeOfWorkTypeCount == 0) {
      this.toastr.error("Atleast one project comprehensive scope is required.", "Error!");
      return;
    }

    let projectScheduleCount = ((this.proposalFrm.get('projectSchedule') as FormArray).length);
    if (projectScheduleCount == 0) {
      this.toastr.error("Atleast one project delivery schedule is required.", "Error!");
      return;
    }
    console.log(this.proposalFrm.get('costDetails') as FormArray);
    let costDetailsCount = ((this.proposalFrm.get('costDetails') as FormArray).length);
    if (costDetailsCount == 0) {
      this.toastr.error("Atleast one payment stage details is required.", "Error!");
      return;
    }
    let costDetailsArray = ((this.proposalFrm.get('costDetails') as FormArray).length);
    let totalPercentage = 0;
    for (let i = 0; i < costDetailsArray; i++) {
      let Percentage = this.proposalFrm.controls['costDetails']['controls'][i]['controls']['percentage'].value;
      totalPercentage = totalPercentage + parseFloat(Percentage);

    }

    if (totalPercentage != 100) {
      this.toastr.error("The sum of percentage in payment stage details must be 100.", "Error!");
      return;
    }
    this.proposalFrm.value.id = this.id;
    if (this.proposalFrm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    this.ProposalModuleService.createProposalRevision(this.id, this.loginId, this.proposalFrm.value).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);
        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/proposal/proposal/proposallist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  UpdateApproveReject(status: any) {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.proposalFrm.value.id = this.id;
    if (this.proposalFrm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    this.ProposalModuleService.UpdateApproveReject(this.id, this.proposalFrm.value, status).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);
        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/proposal/proposal/proposallist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }
  projectCost: any;
  changeOpportunity(opportunityId: any) {
    this.ProposalModuleService.getOpportunityDetailsByOpportunityId(opportunityId).subscribe((e: any) => {
      this.proposalFrm.controls['customerId'].setValue(e.data['clientName']);
      this.proposalFrm.controls['projectName'].setValue(e.data['projectName']);

      this.proposalFrm.controls['projectCode'].setValue(e.data['projectCode']);

      this.proposalFrm.controls['projectAddress'].setValue(e.data['siteAddress']);
      this.proposalFrm.controls['siteArea'].setValue(e.data['landArea']);

      this.getScopeList(e.data['projectTypeId']);
      this.getDeliveryScheduleList(e.data['projectTypeId']);
      this.proposalFrm.patchValue(e.data);
      this.proposalFrm.controls['landUom'].setValue(e.data['landAreaUOM']);
      this.proposalFrm.controls['fsiBuiltUp'].setValue(e.data['fsiApproximate']);
      this.proposalFrm.controls['fsiBuiltUpUom'].setValue(e.data['fsiUom']);
      this.proposalFrm.controls['constructionApprox'].setValue(e.data['constructionApproximate']);
      this.proposalFrm.controls['constructionApproxUom'].setValue(e.data['constructionUOM']);
      this.proposalFrm.controls['projectType'].setValue(e.data['projectTypeId']);
      this.getCustomerContactDetails(e.data['clientName']);

      this.ProposalModuleService.getAreaWiseRateByAreaId(e.data['location']).subscribe((a: any) => {
        console.log(a.data);

        if (e.data['constructionUOM'] == 1) {//ratePerSqMtr
          console.log(e.data['constructionApproximate']);
          console.log(a.data.ratePerSqMtr);
          this.projectCost = parseFloat(e.data['constructionApproximate']) * parseFloat(a.data.ratePerSqMtr);
          this.proposalFrm.controls['rate'].setValue(a.data.ratePerSqMtr);
          this.proposalFrm.controls['projectCost'].setValue(this.projectCost);
          this.calculateTotalCost();
        } else if (e.data['constructionUOM'] == 2) {//e.data.ratePerSqFt
          this.projectCost = parseFloat(e.data['constructionApproximate']) * parseFloat(a.data.ratePerSqFt);
          this.proposalFrm.controls['rate'].setValue(a.data.ratePerSqMtr);
          this.proposalFrm.controls['projectCost'].setValue(this.projectCost);
          this.calculateTotalCost();
        } else {

        }
        //alert(e.data['constructionUOM']);
        //alert(e.data['location']);
      });

      //location
    });
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.proposalFrm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    alert(invalid);
  }

  getProjectScheduleControls() {
    return (this.proposalFrm.get("projectSchedule") as FormArray).controls;
  }

  addProjectSchedule() {
    const documentArray = this.proposalFrm.controls.projectSchedule as FormArray;
    documentArray.push(
      this._formBuilder.group({
        projectScheduleId: [""],
        deliveryScheduleId: ["", Validators.required],
        paymentAgainst: ["", Validators.required],
        projectScheduleDescription: ["", Validators.required],
        projectScheduleRemark: ["", Validators.required],
      })
    );
  }

  deleteProjectSchedule(index) {
    let projectSchedule = this.proposalFrm.controls.projectSchedule as FormArray;
    let projectSchedule_id = projectSchedule.value[index].projectScheduleId;
    // alert('index'+index);
    // alert(projectSchedule_id);
    if (this.action === "Add") {
      (this.proposalFrm.get('projectSchedule') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    } else {
      if (!(projectSchedule_id)) {
        (this.proposalFrm.get('projectSchedule') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      } else {
        this.ProposalModuleService.deleteProjectSchedule(projectSchedule_id).subscribe((response: any) => {
          if (response.code == 1) {
            this.toastr.success("Success !", response.msg);

            this.router
              .navigateByUrl("/app/refresh", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["proposal/proposal/proposalcreate", this.id, this.action]);
              });
            return true;
          } else if (response.code == 0) {
            this.toastr.error(response.msg, "Error!");
            return false;
          } else {
            this.toastr.error("Record not created!", "Error!");
            return false;
          }
        },
          error => {
            this.toastr.warning("Internal Server Error !!", "Error!");
            return false;
          }
        );
      }
    }
  }


  getControls() {
    return (this.proposalFrm.get("costDetails") as FormArray).controls;
  }

  addCostDetails() {
    const documentArray = this.proposalFrm.controls.costDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        costDetailsId: [""],
        stageOfWork: ["", Validators.required],
        paymentValue: ["", Validators.required],
        details: ["", Validators.required],
        percentage: ["", Validators.required],
        description: ["", Validators.required],
      })
    );
  }

  deleteProposalServiceDetails(index) {
    const proposalServiceDetails = this.proposalFrm.controls.proposalServiceDetails as FormArray;
    let serviceDetails_id = proposalServiceDetails.value[index].serviceId;
    if (this.action === "Add") {
      (this.proposalFrm.get('proposalServiceDetails') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    } else {
      if (!(serviceDetails_id)) {
        (this.proposalFrm.get('proposalServiceDetails') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      } else {
        this.ProposalModuleService.deleteProposalServiceDetails(serviceDetails_id).subscribe((response: any) => {
          if (response.code == 1) {
            this.toastr.success("Success !", response.msg);

            this.router
              .navigateByUrl("/app/refresh", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["proposal/proposal/proposalcreate", this.id, this.action]);
              });


            return true;
          } else if (response.code == 0) {
            this.toastr.error(response.msg, "Error!");
            return false;
          } else {
            this.toastr.error("Record not created!", "Error!");
            return false;
          }
        },
          error => {
            this.toastr.warning("Internal Server Error !!", "Error!");
            return false;
          }
        );
      }
    }
  }

  deletePaymentStageDetails(index) {
    let costDetails = this.proposalFrm.controls.costDetails as FormArray;
    let costDetails_id = costDetails.value[index].costDetailsId;
    if (this.action === "Add") {
      (this.proposalFrm.get('costDetails') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    } else {
      if (!(costDetails_id)) {
        (this.proposalFrm.get('costDetails') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      } else {
        this.ProposalModuleService.deletePaymentStageDetails(costDetails_id).subscribe((response: any) => {
          if (response.code == 1) {
            this.toastr.success("Success !", response.msg);

            this.router
              .navigateByUrl("/app/refresh", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["proposal/proposal/proposalcreate", this.id, this.action]);
              });


            return true;
          } else if (response.code == 0) {
            this.toastr.error(response.msg, "Error!");
            return false;
          } else {
            this.toastr.error("Record not created!", "Error!");
            return false;
          }
        },
          error => {
            this.toastr.warning("Internal Server Error !!", "Error!");
            return false;
          }
        );
      }
    }
  }

  getScopeControls() {
    return (this.proposalFrm.get("scopeOfWork") as FormArray).controls;
  }
  addScopeOfWork() {
    const documentArray = this.proposalFrm.controls.scopeOfWork as FormArray;
    documentArray.push(
      this._formBuilder.group({
        scopeOfWorkId: [""],
        scopeOfWorkType: ["", Validators.required],
        projectScopeId: ["", Validators.required],
      })
    );
  }

  addProposalServiceDetails() {
    const documentArray = this.proposalFrm.controls.proposalServiceDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        serviceId: [""],
        sevices: ["", Validators.required],
      })
    );
  }

  getProposalServiceDetails() {
    return (this.proposalFrm.get("proposalServiceDetails") as FormArray).controls;
  }

  getScopeList(projectTypeId: any) {
    // alert('changeState');
    this.ProposalModuleService.getScopeByProjectTypeId(projectTypeId).subscribe((e: any) => {
      //console.log(e.data);
      this.scopeList = e.data;
    });
  }

  deleteScopeOfWork(index) {
    let scopeOfWork = this.proposalFrm.controls.scopeOfWork as FormArray;
    let scopeOfWork_id = scopeOfWork.value[index].scopeOfWorkId;
    if (this.action === "Add") {
      (this.proposalFrm.get('scopeOfWork') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    } else {
      if (!(scopeOfWork_id)) {
        (this.proposalFrm.get('scopeOfWork') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      } else {
        this.ProposalModuleService.deleteScopeOfWork(scopeOfWork_id).subscribe((response: any) => {
          if (response.code == 1) {
            this.toastr.success("Success !", response.msg);

            this.router
              .navigateByUrl("/app/refresh", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["proposal/proposal/proposalcreate", this.id, this.action]);
              });
            return true;
          } else if (response.code == 0) {
            this.toastr.error(response.msg, "Error!");
            return false;
          } else {
            this.toastr.error("Record not created!", "Error!");
            return false;
          }
        },
          error => {
            this.toastr.warning("Internal Server Error !!", "Error!");
            return false;
          }
        );
      }
    }
  }


  getDeliveryScheduleList(projectTypeId: any) {
    // alert('changeState');
    this.ProposalModuleService.getDeliveryScheduleByProjectTypeId(projectTypeId).subscribe((e: any) => {
      //console.log(e.data);
      this.deliveryScheduleList = e.data;
    });
  }

  getScopeDetails(id, index) {
    //  this.showCard = true;
    // let model = "MachineMaster";
    // this.MasterService.List("MachineMaster",{"condition":"t.active = 1 and machine_type= "+id}).then(
    //   res => {
    //     this.scopeList[index] = res;
    //   }
    // );
  }

  onDateChange(newDate: Date) {
    console.log(newDate);
  }

  isFeasibility(e: any) {
    if (e.target.checked) {
      this.isFeasibilityAnalysisPaidStyle = true;
      this.proposalFrm.get('feasibilityCharges').setValidators([Validators.required]);
      this.proposalFrm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(1);
    } else {
      this.isFeasibilityAnalysisPaidStyle = false;
      this.proposalFrm.get('feasibilityCharges').clearValidators();
      this.proposalFrm.controls['isFeasibilityAnalysisPaidCheckbox'].setValue(0);
    }
    this.calculateTotalCost();
  }

  getDescription(e: any, index) {
    let deliveryScheduleId = e.target.value;
    this.ProposalModuleService.getDescriptionByDeliveryScheduleId(deliveryScheduleId).subscribe((e: any) => {
      this.proposalFrm.controls['projectSchedule']['controls'][index]['controls']['projectScheduleDescription'].setValue(e.data['scheduleDescription']);

    });
  }
  rateChange() {
    let rate = this.proposalFrm.value.rate;
    //alert("rate->" + rate);
    let constructionApprox = this.proposalFrm.value.constructionApprox;
    //alert('constructionApprox-->' + constructionApprox);
    this.projectCost = parseFloat(constructionApprox) * parseFloat(rate);
    //alert('projectCost->' + this.projectCost);
    this.proposalFrm.controls['projectCost'].setValue(this.projectCost);
    this.calculateTotalCost();
  }
  calculateTotalCost() {
    let projectCost = this.proposalFrm.value.projectCost;
    let feasibilityCharges = this.proposalFrm.value.feasibilityCharges;
    if (feasibilityCharges > projectCost) {
      this.toastr.warning("Feasibility charges should not greater than project cost !!", "Error!");
      feasibilityCharges = 0;
      this.proposalFrm.controls['feasibilityCharges'].setValue(0);
    }
    let isFeasibilityAnalysisPaidCheckbox = this.proposalFrm.value.isFeasibilityAnalysisPaidCheckbox;
    let total = 0;
    // alert("projectCost->" + projectCost);
    // alert("isFeasibilityAnalysisPaidCheckbox->" + isFeasibilityAnalysisPaidCheckbox);
    // alert("feasibilityCharges->" + feasibilityCharges);
    if (isFeasibilityAnalysisPaidCheckbox == 1) {
      // if (projectCost && feasibilityCharges) {
      // alert('secod if');
      //total = parseFloat(projectCost) + parseFloat(feasibilityCharges);
      total = parseFloat(projectCost);
      // }
    } else {
      if (projectCost) {
        total = projectCost;
      }
    }
    this.proposalFrm.controls['totalCost'].setValue(total);
  }

  calculateStagePayment(counter: any, percentage: any) {
    if (percentage > 100) {
      this.proposalFrm.controls['costDetails']['controls'][counter]['controls']['percentage'].setValue(0);
      this.toastr.error("Please insert below 100 percent.", "Error!");
      return;
    }
    let totalCost = this.proposalFrm.value.totalCost;
    if (totalCost == "") {
      this.toastr.error("Please insert project cost.", "Error!");
      return;
    }
    //alert(totalCost);
    let payment = 0;
    if (totalCost && percentage) {
      payment = (parseFloat(totalCost) * parseFloat(percentage)) / 100;
      if (payment) {
        this.proposalFrm.controls['costDetails']['controls'][counter]['controls']['paymentValue'].setValue(payment);
      }
    } else {
      this.proposalFrm.controls['costDetails']['controls'][counter]['controls']['percentage'].setValue(0);
    }
  }

  changeDeliveryScheduleChage(index: any, deliveryScheduleId: any) {
    //getTaskWeitageUsingDeliveryScheduleId
    this.ProposalModuleService.getTaskWeitageUsingDeliveryScheduleId(deliveryScheduleId).subscribe((e: any) => {
      // alert(e.data['totalTaskWeightage']);
      this.proposalFrm.controls['costDetails']['controls'][index]['controls']['percentage'].setValue(e.data['totalTaskWeightage']);
      this.calculateStagePayment(index, e.data['totalTaskWeightage'])
    });
  }

  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  goBack() {
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/proposal/proposal/proposallist"]);
    });
  }

  onCancel(){

  }
}
