import { Component, OnInit,ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { OpportunityModuleService } from 'src/app/services/opportunity-module.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-opportunity-followup-create',
  templateUrl: './opportunity-followup-create.component.html',
  styleUrls: ['./opportunity-followup-create.component.sass']
})
export class OpportunityFollowupCreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  opportunityFollowupForm: FormGroup;
  submitted: boolean;
  pageTitle: string;
  userList = [];
  OpportunityFollowupDetilsList: any[];
  myDateValue: Date;
  model: NgbDateStruct;
  date: { year: number, month: number };
  @ViewChild('dp') dp: NgbDatepicker;
  time = { hour: 13, minute: 30 };
  time2 = { hour: 13, minute: 30 };
  projectCode:string;
  customerContactDetailsData = [];
  opportunityData = [];

  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public OpportunityModuleService: OpportunityModuleService,
    private calendar: NgbCalendar,
    private atp: AmazingTimePickerService,private spinnerService: Ng4LoadingSpinnerService

  ) { }

  ngOnInit() {
    const token_key = localStorage.getItem("token");
    this.pageTitle = "Opportunity Followup Task";
    this.myDateValue = new Date();
    this.action = this.route.snapshot.paramMap.get("action");
    this.id = this.route.snapshot.paramMap.get("id");
    this.OpportunityModuleService.getEmployeeList().subscribe((e: any) => {
      this.userList = e.data;
    });
    this.opportunityFollowupForm = this.createOppertunityFollowupForm();
    this.opportunityFollowupForm.controls['type'].setValue(1);
    this.OpportunityModuleService.GetOpportunityFollowupDetailsById(this.id).then(data => {
      this.opportunityFollowupForm.controls['typeId'].setValue(data.data['typeId']);
      this.opportunityFollowupForm.controls['assigned_to'].setValue(data.data['assigned_to']);
      this.OpportunityModuleService.GetOpportunityDetailsByIdForView(data.data['typeId']).then(data => {
        this.opportunityData = data.data.opportunity;
      });
      this.OpportunityModuleService.GetOpportunityFollowupDetailsList(data.data['typeId']).then(data => {
        this.OpportunityFollowupDetilsList = data.data;
        if(data.data.length > 0 ){
          this.getCustomerContactDetails(data.data[0]['clientId']);
          this.projectCode = data.data[0]['projectCode'];
          this.opportunityFollowupForm.controls['projectCode'].setValue(this.projectCode);
        }
      });

    });
  }

  createOppertunityFollowupForm(): FormGroup {
    this.spinnerService.show();
    return (this.opportunityFollowupForm = this._formBuilder.group({
      projectCode:[""],
      type :[""],
      typeId :["", Validators.required],
      assigned_to :["", Validators.required],
      nextFollowupDate :["", Validators.required],
      nextFollowUpTime: ["", Validators.required],
      remark :["", Validators.required],
      status :["", Validators.required],
    }));
  }

  get f() {
    return this.opportunityFollowupForm.controls;
  }
  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }
  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.opportunityFollowupForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.OpportunityModuleService.createOpportunityFollowup(this.id,this.opportunityFollowupForm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/dashboard"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  getstatus(status:any){
    if(status == 1){
      return "Pending";
    }else if(status == 2){
      return "Partial Interested";
    }else if(status == 3){
      return "Interested";
    }else if(status == 4){
      return "Not Interested";
    }
    return "NA";
  }

  ProjectScale(status:any){
    if(status == 1){
      return "Large >4cr";
    }else if(status == 2){
      return "Mid 40L< 4cr";
    }else if(status == 3){
      return "Small < 40L";
    }
    return "NA";
  }
  open(ev: any) {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => {
      this.opportunityFollowupForm.controls['nextFollowUpTime'].setValue(time);
    });
  }

  getCustomerContactDetails(id:any){
    this.OpportunityModuleService.getCustomerContactDetails(id).then(data => {
      this.customerContactDetailsData = data.data;
    });
  }

}
