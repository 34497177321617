import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
//import { environment } from "./../../environments/environment";
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};

@Injectable({
  providedIn: 'root'
})

export class MastersService {
  headers: HttpHeaders | { [header: string]: string | string[] };
  constructor(private httpClient: HttpClient) { }


  getProjectTypeList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=project_type");
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  CreateProjectType(projecttype: any): Observable<boolean> {
    return this.httpClient.post<boolean>(
      environment.url + "api/projectTypeCreateUpdate&projectType=" + projecttype,
      JSON.stringify(projecttype)
    );
  }
  UpdateProjectType(id: any, getnameprotype: string): any {
    return this.httpClient.post(
      environment.url + "api/projectTypeCreateUpdate&projectType=" + getnameprotype + "&id=" + id,
      {
        id: id,
        projectType: getnameprotype
      }

    );
  }

  GetProjectTypebyID(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getProjectTypeDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  updateProjectTypeStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateProjectTypeStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  /////////////////////////////////

  SaveDepartmentData(deptName, active) {
    return this.httpClient.get("https://reqres.in/api/users?page=2" + deptName + "," + active);
  }

  getSingleEmpData() {
    return this.httpClient.get("https://reqres.in/api/users/2").toPromise();
  }

  getEmpData() {
    return this.httpClient.get("https://reqres.in/api/users?page=2");
  }

  createState(statefrm) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createState",
      JSON.stringify(statefrm)
    );
  }

  GetStatebyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetStateDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  updateState(id: any, statefrm) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/updateState&id=" + id,
      JSON.stringify(statefrm)
    );
  }

  updateFirmTypeStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateFirmTypeStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }

  createArea(form: any) {
    // return this.httpClient.get(environment.url + "api/createUpdateArea");
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateArea",
      JSON.stringify(form)
    );
  }
  updateArea(id: any, form: any) {
    // return this.httpClient.get(environment.url + "api/createUpdateArea");
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateArea&id=" + id,
      JSON.stringify(form)
    );
  }

  updateAreaStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateAreaStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }
    );
  }

  getState() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=state_master");
  }

  updateStateStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateStateStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }

  getCityByStateId(stateId) {
    return this.httpClient.get(environment.url + "api/GetCity&stateId=" + stateId);
  }
  getAreaList() {
    return this.httpClient.get(environment.url + "api/getAreaDetails");
  }
  getAreaDetails1(id) {
    return this.httpClient.get(environment.url + "api/getAreaDetails&id=" + id);
  }

  getAreaDetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getAreaDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  getFirmType() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=firm_type_master");
  }

  createFirmType(firmType) {
    return this.httpClient.get(environment.url + "api/firmTypeCreateUpdate&firmType=" + firmType);
  }

  updateFirmType(id: any, firmType: string): any {
    //alert('updateFirmType');
    return this.httpClient.post(
      environment.url + "api/firmTypeCreateUpdate&firmType=" + firmType + "&id=" + id,
      {
        id: id,
        stateName: firmType
      }

    );
  }


  GetFirmTypebyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/GetFirmTypeDetails&id=" + id, {
      id: id
    }).toPromise().catch(this.handleError);
  }
  ///////////////////////
  getTermsAndConditionCategoryList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=terms_and_condition_category_master");
  }

  createTermsAndConditionCategory(categoryName) {
    return this.httpClient.get(environment.url + "api/termsAndConditionCategoryCreateUpdate&categoryName=" + categoryName);
  }

  GetTermsAndConditionCategoryDetails(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/GetTermsAndConditionCategoryDetails&id=" + id, {
      id: id
    }).toPromise().catch(this.handleError);
  }

  updateTermsAndConditionCategory(id: any, categoryName: string): any {
    return this.httpClient.post(environment.url + "api/termsAndConditionCategoryCreateUpdate&categoryName=" + categoryName + "&id=" + id, {
      id: id,
      stateName: categoryName
    });
  }
  //////////////////////////////////////////
  getDepartmentList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=department_master");
  }

  createDepartment(department) {
    return this.httpClient.get(environment.url + "api/departmentCreate&department=" + department);
  }

  GetDepartmentbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetDepartmentDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  updateDepartment(id: any, getnameprotype: string): any {
    return this.httpClient.post(
      environment.url + "api/updateDepartment&department=" + getnameprotype + "&id=" + id,
      {
        id: id,
        department: getnameprotype
      }

    );
  }

  updateDepartmentStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateDepartmentstatus&status=" + status + "&id=" + id,
      {
        id: id,
        department: status
      }

    );
  }
  /////////////////////////////////////////
  getOccupationList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=occupation_master");
  }

  createOccupation(occupation) {
    return this.httpClient.get(environment.url + "api/occupationCreate&occupation=" + occupation);
  }

  GetOccupationbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetOccupationDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  updateOccupation(id: any, getnameprotype: string): any {
    return this.httpClient.post(
      environment.url + "api/updateOccupation&occupation=" + getnameprotype + "&id=" + id,
      {
        id: id,
        department: getnameprotype
      }

    );
  }

  updateOccupationStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateOccupationStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  ///////////////////////////////////////////////
  getDesignationList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=designation_master");
  }
  createDesingnation(designation) {
    return this.httpClient.get(environment.url + "api/designationCreate&designation=" + designation);
  }
  updateDesignationStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateDesignationStatus&status=" + status + "&id=" + id,
      {
        id: id,
        status: status
      }
    );
  }
  updateDesignation(id: any, getnameprotype: string): any {
    return this.httpClient.post(
      environment.url + "api/updateDesignation&designation=" + getnameprotype + "&id=" + id,
      {
        id: id,
        designation: getnameprotype
      }
    );
  }
  GetDesignationbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetDesignationDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  ///////////////////////////////////////////////
  // getCompanyList() {
  //   return this.httpClient.get(environment.url + "api/GetDetails&table=company_master");
  // }

  getAreaByCityId(cityId) {
    return this.httpClient.get(environment.url + "api/getAreaByCityId&cityId=" + cityId);
  }

  createCompany(companyName, companyCode, officeAddress, stateId, cityId, areaId, emailId, panNumber, gstinNumber) {
    return this.httpClient.get(environment.url + "api/createUpdateCompany&companyName=" + companyName + "&companyCode=" + companyCode + "&officeAddress=" + officeAddress + "&stateId=" + stateId + "&cityId=" + cityId + "&areaId=" + areaId + "&emailId=" + emailId + "&panNumber=" + panNumber + "&gstinNumber=" + gstinNumber);
  }


  getCompanyDetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getCompanyDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  updateCompany(id: any, companyName: string, companyCode: string, officeAddress: string, stateId: string, cityId: string, areaId: string, emailId: string, panNumber: string, gstinNumber: string): any {
    return this.httpClient.post(
      environment.url + "api/createUpdateCompany&id=" + id + "&companyName=" + companyName + "&companyCode=" + companyCode + "&officeAddress=" + officeAddress + "&stateId=" + stateId + "&cityId=" + cityId + "&areaId=" + areaId + "&emailId=" + emailId + "&panNumber=" + panNumber + "&gstinNumber=" + gstinNumber,
      {
        id: id,
        stateName: companyName,
        companyCode: companyCode,
        officeAddress: officeAddress,
        stateId: stateId,
        cityId: cityId,
        areaId: areaId,
        emailId: emailId,
        panNumber: panNumber,
        gstinNumber: gstinNumber,
      }

    );
  }


  updateCompanyStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateCompanyStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  ////////////////////////////////////
  getserviceslist() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=services_master");
  }

  createService(servicename) {
    return this.httpClient.get(environment.url + "api/serviceCreateUpdate&servicename=" + servicename);
  }

  updateservice(id: any, servicename: string): any {
    return this.httpClient.post(
      environment.url + "api/serviceCreateUpdate&servicename=" + servicename + "&id=" + id,
      {
        id: id,
        servicename: servicename
      }

    );
  }
  GetServicebyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetServiceDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  updateservicestatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateservicestatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }
  ////////////////////////////////////
  getprojectScopeList() {
    return this.httpClient.get(environment.url + "api/getProjectScopeList");
  }


  createProjectScope(projectTypeId, scopeDescription) {
    return this.httpClient.get(environment.url + "api/projectScopeCreateUpdated&projectTypeId=" + projectTypeId + "&scopeDescription=" + scopeDescription);
  }

  getProjectScopeDetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getProjectScopeDetails&id=" + id, {
        id: id
      }).toPromise().catch(this.handleError);
  }

  getActiveProjectTypeList() {
    return this.httpClient.get(environment.url + "api/getActiveProjectTypeList");
  }

  getActiveDetailsListForUpdate(id, table) {
    return this.httpClient.get(environment.url + "api/getActiveDetailsListForUpdate&table=" + table + "&id=" + id + "");
  }

  getActiveDetailsListForCreate(table) {
    return this.httpClient.get(environment.url + "api/getActiveDetailsListForCreate&table=" + table + "");
  }

  updateProjectScope(id, projectTypeId, scopeDescription) {
    return this.httpClient.get(environment.url + "api/projectScopeCreateUpdated&id=" + id + "&projectTypeId=" + projectTypeId + "&scopeDescription=" + scopeDescription);
  }

  updateProjectScopeStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateProjectScopeStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  updateTaskTypaeStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateTaskTypaeStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  ///////////////////////////
  getLeaveTypeList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=leave_type_master");
  }

  createLeaveType(leaveType) {
    return this.httpClient.get(environment.url + "api/leaveTypeCreateUpdate&leaveType=" + leaveType);
  }

  GetLeaveTypebyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getLeaveTypeDetails&id=" + id, {
      id: id
    }).toPromise().catch(this.handleError);
  }

  updateLeaveTypeStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateLeaveTypeStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }

  updateLeaveType(id: any, leaveType: string): any {
    //alert('updateFirmType');
    return this.httpClient.post(
      environment.url + "api/leaveTypeCreateUpdate&leaveType=" + leaveType + "&id=" + id,
      {
        id: id,
        stateName: leaveType
      }

    );
  }

  ///////////////

  getHolidayCalanderList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=holiday_calender");
  }

  createHoliday(FormDetails: any) {
    console.log(FormDetails);
    return this.httpClient.post<boolean>(
      environment.url + "/api/holidayCreateUpdate",
      JSON.stringify(FormDetails)
    );
  }

  updateHoliday(id: any, getnameprotype: string) {
    return this.httpClient.post(
      environment.url + "api/holidayCreateUpdate&id=" + id,
      {
        id: id,
        data: getnameprotype
      }

    );
  }

  updateHolidayStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateHolidayStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }

  GetHolidaybyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getHolidaybyId&id=" + id, {
      id: id
    }).toPromise().catch(this.handleError);
  }
  /////////////////////////////////////////
  getTaskTypeList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=task_type_master");
  }

  createTaskType(taskType) {
    return this.httpClient.get(environment.url + "api/taskTypeCreateUpdate&taskType=" + taskType);
  }

  getTaskTypebyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getTaskTypebyId&id=" + id, {
      id: id
    }).toPromise().catch(this.handleError);
  }

  updateTaskType(id: any, taskType: string): any {
    //alert('updateFirmType');
    return this.httpClient.post(
      environment.url + "api/taskTypeCreateUpdate&taskType=" + taskType + "&id=" + id,
      {
        id: id,
        stateName: taskType
      }

    );
  }
  /////////////////////

  getTaskCreationListList() {
    return this.httpClient.get(environment.url + "api/getTaskCreationList");
  }

  createTaskCreation(taskTypeid, taskDescription) {
    return this.httpClient.get(environment.url + "api/taskCreationCreateUpdate&taskTypeid=" + taskTypeid + "&taskDescription=" + taskDescription);
  }

  getTaskCreationbyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getTaskCreationbyId&id=" + id, {
      id: id
    }).toPromise().catch(this.handleError);
  }

  updateTaskCreation(id: any, taskTypeid: string, taskDescription: string): any {
    //alert('updateFirmType');
    return this.httpClient.post(
      environment.url + "api/taskCreationCreateUpdate&id=" + id + "&taskTypeid=" + taskTypeid + "&taskDescription=" + taskDescription,
      {
        id: id,
        taskTypeid: taskTypeid,
        stateName: taskDescription
      }

    );
  }

  getTaskType() {
    return this.httpClient.get(environment.url + "api/getTaskType");
  }

  updateTaskCreationStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateTaskCreationStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  ///////////////////////////////////

  gettermsconditions() {
    return this.httpClient.get(environment.url + "api/gettermsconditions");
  }

  createCondition(category, condition) {
    return this.httpClient.get(environment.url + "api/createtermscondtion&category=" + category + "&condition=" + condition);
  }

  getcategory() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=terms_and_condition_category_master");
  }

  gettermsdetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/gettermsconditionbyid&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  updateterms(id: any, category: any, condition: any): any {
    return this.httpClient.post(
      environment.url + "api/updatetermscondition&category=" + category + "&condition=" + condition + "&id=" + id,
      {
        id: id,
        category: category,
        condition: condition
      }

    );
  }

  updatetermstatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updatetermstatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }
    );
  }
  ///////////////////////////////////
  /**
    *Discription : Customer master api's.
    *@author: Ram Garole
    * Date: 19-05-21
  */

  getCustomerList() {
    return this.httpClient.get(environment.url + "api/GetCustomerDetails");
  }
  GetCustomerById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetCustomerDetailsById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  updateCustomerStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateCustomerStatus&status=" + status + "&id=" + id,
      {
        id: id,
        status: status
      }

    );
  }

  CreateCustomer(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/CreateUpdateCustomer",
      JSON.stringify(FormDetails)
    );
  }

  updateCustomer(id: any, FormDetails: string): any {
    return this.httpClient.post<boolean>(
      environment.url + "/api/CreateUpdateCustomer&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  getareaByCityId(areaId) {
    return this.httpClient.get(environment.url + "api/GetAreaByCity&areaId=" + areaId);
  }
  getCompanyList() {
    return this.httpClient.get(environment.url + "api/getCompanyList");
  }

  getDesignation() {
    return this.httpClient.get(environment.url + "api/getDesignation");
  }

  geDepartmentList() {
    return this.httpClient.get(environment.url + "api/geDepartmentList");
  }

  GetCustomerContactDetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetCustomerContactDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  getNewClientCode() {
    return this.httpClient.get(environment.url + "api/getNewClientCode");
  }

  geFirmList() {
    return this.httpClient.get(environment.url + "api/geFirmList");
  }
  deleteCustomerContactDetails(detailsId) {
    return this.httpClient.post(
      environment.url + "api/deleteCustomerContactDetails&id=" + detailsId,
      {
        id: detailsId,
      }
    );
  }
  deleteEmployeeBankDetails(employeeBankDetailsId) {
    return this.httpClient.post(
      environment.url + "api/deleteEmployeeBankDetails&id=" + employeeBankDetailsId,
      {
        id: employeeBankDetailsId,
      }
    );
  }

  /**
    END
  */

  /**
    *Discription : Delivery Schedule master api's.
    *@author: Ram Garole
    * Date: 25-05-21
  */


  getDeliveryScheduleList() {
    return this.httpClient.get(environment.url + "api/GetDeliveryScheduleList");
  }
  updateDeliverySCheduleStatus(id: any, status: any) {
    return this.httpClient.post(
      environment.url + "api/updateDeliveryScheduleStatus&status=" + status + "&id=" + id,
      {
        id: id,
        status: status
      }

    );
  }
  createDeliverySchedule(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateDeliverySchedule",
      JSON.stringify(FormDetails)
    );
  }
  GetDeliverySchedulebyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetDeliveryScheduleDetailsById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  updateDeliverySchedule(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateDeliverySchedule&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  /**
    END
  */

  /**
    *Discription : Project scope master api's.
    *@author: Ram Garole
    * Date: 25-05-21
  */

  getProjectScopeList() {
    return this.httpClient.get(environment.url + "api/getProjectScopeList");
  }
  // updateProjectScopeStatus(id: any, status: any){
  //   return this.httpClient.post(
  //     environment.url + "api/updateProjectScopeStatus&status=" + status + "&id=" + id,
  //     {
  //       id: id,
  //       status: status
  //     }

  //   );
  // }
  // createProjectScope(FormDetails:any){
  //   return this.httpClient.post<boolean>(
  //     environment.url + "/api/createUpdateProjectScope",
  //     JSON.stringify(FormDetails)
  //   );
  // }
  GetProjectScopeDetailsById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetProjectScopeDetailsById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  // updateProjectScope(id:any,FormDetails:any){
  //   return this.httpClient.post<boolean>(
  //     environment.url + "/api/createUpdateProjectScope&id="+id,
  //     JSON.stringify(FormDetails)
  //   );
  // }
  /**
    END
  */

  /**
    *Discription : Project task master api's.
    *@author: Ram Garole
    * Date: 26-05-21
  */
  getProjectTaskList() {
    return this.httpClient.get(environment.url + "api/getProjectTaskList");
  }
  updateProjectTaskStatus(id: any, status: any) {
    return this.httpClient.post(
      environment.url + "api/updateProjectTaskStatus&status=" + status + "&id=" + id,
      {
        id: id,
        status: status
      }

    );
  }
  GetProjectTaskDetailsById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetProjectTaskDetailsById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  createProjectTask(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateProjectTask",
      JSON.stringify(FormDetails)
    );
  }
  updateProjectTask(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateProjectTask&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  /**
    END
  */
  /**
     *Discription : Project mapping master api's.
     *@author: Ram Garole
     * Date: 26-05-21
   */
  getProjectMappingList() {
    return this.httpClient.get(environment.url + "api/getProjectMappingList");
  }
  updateProjectMappingStatus(id: any, status: any) {
    return this.httpClient.post(
      environment.url + "api/updateProjectMappingStatus&status=" + status + "&id=" + id,
      {
        id: id,
        status: status
      }

    );
  }
  getTaskList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=project_task_master");
  }

  createProjectMapping(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateProjectMapping",
      JSON.stringify(FormDetails)
    );
  }
  updateProjectMapping(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateProjectMapping&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  GetProjectMappingDetailsById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetProjectMappingDetailsById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  /**
    END
  */

  ////////////////////////////////////
  getproposalTemplatesTypeList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=proposal_templates_type");
  }

  createProposalTemplatesType(proposalType) {
    return this.httpClient.get(environment.url + "api/createProposalTemplatesTypeCreateUpdated&proposalType=" + proposalType);
  }
  updateProposalTemplatesType(id, proposalType) {
    return this.httpClient.get(environment.url + "api/createProposalTemplatesTypeCreateUpdated&id=" + id + "&proposalType=" + proposalType);
  }

  getProposalTypeDetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getProposalTypeDetails&id=" + id, {
        id: id
      }).toPromise().catch(this.handleError);
  }

  ////////////////////////////

  getproposalTemplatesCreationList() {
    return this.httpClient.get(environment.url + "api/GetProposalTemplatesList");
  }

  createProposalTemplates(proposalTypeId, template) {
    return this.httpClient.get(environment.url + "api/proposalTemplatesCreateUpdated&proposalTypeId=" + proposalTypeId + "&template=" + template);
  }

  updateProposalTemplates(id, proposalTypeId, template) {
    return this.httpClient.get(environment.url + "api/proposalTemplatesCreateUpdated&id=" + id + "&proposalTypeId=" + proposalTypeId + "&template=" + template);
  }

  getProposalDetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getProposalDetails&id=" + id, {
        id: id
      }).toPromise().catch(this.handleError);
  }

  updateProposalTemplatesStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateProposalTemplatesStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  //////////////////////////////
  getRateConfiguratorList() {
    return this.httpClient.get(environment.url + "api/getRateConfiguratorList");
  }

  createRateConfigurator(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateRateConfigurator",
      JSON.stringify(FormDetails)
    );
  }

  getRateConfiguratorDetails(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getRateConfiguratorDetails&id=" + id, {
      id: id
    }).toPromise().catch(this.handleError);
  }

  updateRateConfigTask(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateRateConfigurator&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  updateRateConfiguratorStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateRateConfiguratorStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  ////////////////////////////start renu code//////////////
  getFeasibilityList() {
    return this.httpClient.get(environment.url + "api/getFeasibilityList");
  }

  updateFeasibilityCriteriaStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateFeasibilityCriteriaStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }
  getFeasibilityCriteriabyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getFeasibilityCriteriaDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  } createFeasibilityCriteria(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateFeasibilityCriteria",
      JSON.stringify(FormDetails)
    );
  }
  updateFeasibilityCriteria(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateFeasibilityCriteria&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  ////////
  getTopologymasterList() {
    return this.httpClient.get(environment.url + "api/getTopologyMasterList");
  }
  updateTopologyMasterStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateTopologyMasterStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }
  getTopologyMasterbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getTopologyMasterDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  createTopologyMaster(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateTopologyMaster",
      JSON.stringify(FormDetails)
    );
  }
  updateTopologyMaster(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateTopologyMaster&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  ///////////////
  getuommasterList() {
    return this.httpClient.get(environment.url + "api/getUomMasterList");
  } updateUomMasterStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateUomMasterStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }
  getUomMasterbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getUomMasterDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  createUomMaster(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateUomMaster",
      JSON.stringify(FormDetails)
    );
  }
  updateUomMaster(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateUomMaster&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  getEmployeeList() {
    return this.httpClient.get(environment.url + "api/getEmpList");
  } updateEmployeeStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateEmpStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  } getEmpbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetEmpDetailsById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  } createEmp(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createEmployee",
      JSON.stringify(FormDetails)
    );
  } updateEmp(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createEmployee&id=" + id,
      JSON.stringify(FormDetails)
    );
  } getDeptList() {
    return this.httpClient.get(environment.url + "api/getDeptList");
  } getCompanyMasterList() {
    return this.httpClient.get(environment.url + "api/getCompanyMasterList");
  } getDesignationMasterList() {
    return this.httpClient.get(environment.url + "api/getDesignationMasterList");
  }  //////// Leave Credit

  getLeaveCreditList() {
    return this.httpClient.get(environment.url + "api/getLeaveCreditList");
  }

  getLeaveCreditbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetLeaveCreditById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  updateLeaveCreditStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateLeaveCreditStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }

  createLeaveCredit(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/CreateUpdateLeaveCredit",
      JSON.stringify(FormDetails)
    );
  }

  updateLeaveCredit(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/CreateUpdateLeaveCredit&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  getFinancialYrMasterList() {
    return this.httpClient.get(environment.url + "api/getfinancialYrMasterList");
  }

  getLeaveCreditTypeList() {
    return this.httpClient.get(environment.url + "api/getLeavecreaditTypeList");
  }
  ////////////////////////////end renu code//////////////
  ////////////////////////////start varad code//////////////
  getcityList() {
    return this.httpClient.get(environment.url + "api/getcitydetails");
  }

  getstate() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=state_master");
  }

  createcity(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdatecity",
      JSON.stringify(FormDetails)
    );
  }

  getcitydetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/cityById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  updatecity(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdatecity&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  updatecitystatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updatecitystatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }
    );
  }
  checkusernameandpassword(uname: string, pwd: string) {
    if (uname == "admin" && pwd == "admin123") {

      localStorage.setItem('username', "admin");
      console.log("password match")
      return true;
    }
    else {
      return false;

    }
  }

  login(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/login",
      JSON.stringify(FormDetails)
    );
  }
  ////////////////////////////end varad code//////////////
  ////////////////////////////////////
  getProposalList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=proposal");
  }

  getOpportunity() {
    return this.httpClient.get(environment.url + "api/getOpportunity");
  }

  getCutomerList() {
    return this.httpClient.get(environment.url + "api/getCutomerList");
  }

  getScopeByProjectTypeId(ProjectTypeId) {
    return this.httpClient.get(environment.url + "api/getScopeByProjectTypeId&ProjectTypeId=" + ProjectTypeId);
  }

  getDeliveryScheduleByProjectTypeId(ProjectTypeId) {
    return this.httpClient.get(environment.url + "api/getDeliveryScheduleByProjectTypeId&ProjectTypeId=" + ProjectTypeId);
  }

  getOpportunityDetailsByOpportunityId(opportunityId) {
    return this.httpClient.get(environment.url + "api/getOpportunityDetailsByOpportunityId&opportunityId=" + opportunityId);
  }

  createPraposal(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/praposalCreateUpdate",
      JSON.stringify(FormDetails)
    );
  }

  getProposalbyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getProposalbyId&proposalId=" + id, {
      proposalId: id
    }).toPromise().catch(this.handleError);
  }

  viewProposalbyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/viewProposalbyId&proposalId=" + id, {
      proposalId: id
    }).toPromise().catch(this.handleError);
  }

  updatePraposal(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/praposalCreateUpdate&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  ///////////////////////////

  deletePaymentStageDetails(id: any) {
    return this.httpClient.post(
      environment.url + "api/deletePaymentStageDetails&id=" + id,
      {
        id: id,
      }
    );
  }

  deleteScopeOfWork(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteScopeOfWork&id=" + id,
      {
        id: id,
      }
    );
  }

  deleteProjectSchedule(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteProjectSchedule&id=" + id,
      {
        id: id,
      }
    );
  }

  updatetermscategory(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updatermscategory&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      }

    );
  }
  /////////////////////////////////////////
  ////////////////  leavecredit
  getLeaveInfo(employeeId: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "/api/GetLeveInformation&employeeId=" + employeeId, {
        observe: 'response'
      })
      .toPromise()
      .catch(this.handleError);
  }//////////////////////  TaxMaster///////
  getTaxMasterList() {
    return this.httpClient.get(environment.url + "api/getTaxMasterList");
  } updateTaxMasterStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateTaxMasterStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }
  createTaxMaster(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateTaxMaster",
      JSON.stringify(FormDetails)
    );
  }
  updateTaxMaster(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateTaxMaster&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  getTaxMasterbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getTaxMasterById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }////////////
  getCompanyBankMasterList() {
    return this.httpClient.get(environment.url + "api/getCompanyBankMasterList");
  } updateCompanyBankMasterStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateCompanyBankMasterStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }
  createCompanyBankMaster(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateCompanyBankMaster",
      JSON.stringify(FormDetails)
    );
  } getCompanyBankMasterbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getCompanyBankMasterById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  } updateCompanyBankMaster(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateCompanyBankMaster&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  //////////////////////////////////////
  getFinancialYearMasterList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=financial_year_master");
  }
  creatUpdateFinancialYearMaster(FormDetails: any, userMasterId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/CreateUpdateFinancialYearMaster&userMasterId=" + userMasterId,
      JSON.stringify(FormDetails)
    );
  }
  GetFinancialYearMasterbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getFinancialYearMasterDetails&fy_id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  UpdateFinancialYearMaster(id: any, getnameprotype: string, loginId: any): any {
    return this.httpClient.post<boolean>(
      environment.url + "api/CreateUpdateFinancialYearMaster&id=" + id + "&userMasterId=" + loginId,
      JSON.stringify(getnameprotype)
    );
  }

  UpdateFinancialYearMasteStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/UpdateFinancialYearMasteStatus&status=" + status + "&id=" + id,
      {
        id: id,
        department: status
      }

    );
  }
  /////////////////////////////////////////

  getSalarySlabMasterList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=salary_slab_master");
  }

  createSalarySlabMaster(FormDetails: any, userMasterId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/SalarySlabMasterCreateUpdate&loginId=" + userMasterId,
      JSON.stringify(FormDetails)
    );
  }

  GetSalarySlabMasterbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetSalarySlabMasterDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  UpdateSalarySlabMaster(id: any, getnameprotype: string, loginId: any): any {
    return this.httpClient.post<boolean>(
      environment.url + "api/SalarySlabMasterCreateUpdate&id=" + id + "&loginId=" + loginId,
      JSON.stringify(getnameprotype)
    );
  }
  UpdateSalarySlabMasterStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/SalarySlabMasterCreateUpdateStatus&status=" + status + "&id=" + id,
      {
        id: id,
        SalarySlabMaster: status
      }

    );
  }
  ////////////////////////////////////////////
  getlateInEarlyOutMasterList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=late_in_early_out_master");
  }

  createlateInEarlyOutMaster(FormDetails: any, userMasterId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/lateInEarlyOutMasterCreateUpdate&loginId=" + userMasterId,
      JSON.stringify(FormDetails)
    );
  }

  GetLateInEarlyOutMasterbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetLateInEarlyOutMasterDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  UpdatelateInEarlyOutMasterForm(id: any, getnameprotype: string, loginId: any): any {
    return this.httpClient.post<boolean>(
      environment.url + "api/lateInEarlyOutMasterCreateUpdate&id=" + id + "&loginId=" + loginId,
      JSON.stringify(getnameprotype)
    );
  }

  UpdateLateInEarlyOutMasterStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/LateInEarlyOutMasterCreateUpdateStatus&status=" + status + "&id=" + id,
      {
        id: id,
        SalarySlabMaster: status
      }

    );
  }

  /////////////////////////////////////////////////
  getSalaryGradeList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=salary_grade");
  }

  createSalaryGrade(FormDetails: any, userMasterId: any, loginId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/SalaryCreateUpdate&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }
  GetSalaryGradebyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetSalaryDetailsById&salaryGradeId=" + id, {
        salaryGradeId: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  GetSalaryById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetSalaryById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  GetSalaryGradeDetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetSalaryGradeDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  UpdatesalaryGradeForm(id: any, getnameprotype: string, loginId: any): any {
    return this.httpClient.post<boolean>(
      environment.url + "api/SalaryCreateUpdate&salaryGradeId=" + id+"&loginId="+loginId,
      JSON.stringify(getnameprotype)
    );
  }
  UpdateSalaryGradeStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateSalaryGradeStatus&isActive=" + status + "&salaryGradeId=" + id,
      {
        salaryGradeId: id,
        isActive: status
      }

    );
  }
  /////////////////////////////////////////////////
  getSalaryComponentList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=salary_component");
  }
  createSalaryComponent(FormDetails: any, salaryCompId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/SalaryComponentCreateUpdate",
      JSON.stringify(FormDetails)
    );
  }
  GetSalaryComponentbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetSalaryComponentDetailsById&salaryCompId=" + id, {
        salaryCompId: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  UpdateSalaryComponentForm(id: any, getnameprotype: string, loginId: any): any {
    return this.httpClient.post<boolean>(
      environment.url + "api/SalaryComponentCreateUpdate&salaryCompId=" + id,
      JSON.stringify(getnameprotype)
    );
  }
  UpdateSalaryComponentStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/SalaryComponenUpdateStatus&status=" + status + "&id=" + id,
      {
        id: id,
        SalarySlabMaster: status
      }

    );
  }
  ///////
  /////////////////////////////////////////////////

  getEmpDocmentById(employeeId: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getEmpDocmentById&employeeId=" + employeeId, {
        employeeId: employeeId
      })
      .toPromise()
      .catch(this.handleError);
  }
  getSalarySlabListForEmployee() {
    return this.httpClient.get(environment.url + "api/getSalarySlabListForEmployee");
  }
  ///////////////////////////////////////////////////////////////////

  GetSalaryGradeList() {
    return this.httpClient.get(environment.url + "api/GetSalaryGradeMasterData");
  }
  createSalaryGradeMaster(FormDetails: any, userMasterId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/SalaryGradeMasterCreateUpdate&loginId=" + userMasterId,
      JSON.stringify(FormDetails)
    );
  }
  GetSalaryGradeMasterById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetSalaryGradeMasterById&salaryGradeMasterId=" + id, {
        salaryGradeMasterId: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  getGradeListForSalary() {
    return this.httpClient.get(environment.url + "api/GetSalaryGradeList");
  }
  getComponentListForSalary() {
    return this.httpClient.get(environment.url + "api/GetSalaryComponentList");
  }
  UpdateSalaryGradeMaster(id: any, getnameprotype: string, loginId: any): any {
    return this.httpClient.post<boolean>(
      environment.url + "api/SalaryGradeMasterCreateUpdate&salaryGradeMasterId=" + id + "&loginId=" + loginId,
      JSON.stringify(getnameprotype)
    );
  }
  UpdateSalaryGradeMasterStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/UpdateSalaryGradeMasterStatus&isActive=" + status + "&salaryGradeMasterId=" + id,
      {
        salaryGradeMasterId: id,
        isActive: status
      }
    );
  }

  ///////////////////////////////////////////////////////////////////
  getDashboardMasterList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=dashboard_master");
  }
  createDashboardMaster(FormDetails: any, userMasterId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/DashBoardMasterCreateUpdate",
      JSON.stringify(FormDetails)
    );
  }
  GetDashboardMasterbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetDashboardMasterDetailsById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  UpdateDashboardMaster(id: any, getnameprotype: string): any {
    return this.httpClient.post<boolean>(
      environment.url + "api/DashBoardMasterCreateUpdate&id=" + id,
      JSON.stringify(getnameprotype)
    );
  }
  UpdateDashboardMasterStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/api/DashboardMasterStatusUpdate&id=" + id,
      {
        id: id,
        isActive: status
      }
    );
  }

  ///////////////////////////////////////////////////////////////////
  getDashboardDesignationMasterList() {
    return this.httpClient.get(environment.url + "api/getDesignationMasterList");
  }
  getMenuDetails(userMasterId: any) {
    return this.httpClient.get(environment.url + "api/getDashboardMenuMasterList&userMasterId=" + userMasterId);
  }
  getTopologyMasterList() {
    return this.httpClient.get(environment.url + "api/getTopologyMasterList");
  }
  getEmpListByDesignation(designationId: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetEmaployeeByDesignationId&designationId=" + designationId, {
        value: designationId
      })
      .toPromise()
      .catch(this.handleError);
  }
  UpdateDashboardMenuAccessMaster(id: any, FormDetails: any, loginId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/UpdateDashboardMenuAccessMaster&userMasterId=" + id + "&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }

  ///////////////////////////////////////////////////////////////////

  getOthersalarycomponentList() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=other_salary_component");
  }

  createOtherSalaryComponent(FormDetails: any, userMasterId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "api/OtherSalaryComponentCreateUpdate",
      JSON.stringify(FormDetails)
    );
  }
  getOthersalarycomponentbyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetSalaryOtherComponentById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  UpdateOtherSalaryComponent(id: any, getnameprotype: string): any {
    return this.httpClient.post<boolean>(
      environment.url + "api/OtherSalaryComponentCreateUpdate&id=" + id,
      JSON.stringify(getnameprotype)
    );
  }
  UpdateOtherSalaryComponentStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/SalaryOtherComponentChangeStatus&id=" + id + "&status=" + status,
      {
        id: id,
        isActive: status
      }
    );
  }

  dashboardMenuAccessDetails(loginId: any): any {
    return this.httpClient.post(
      environment.url + "api/dashboardMenuAccessDetails&loginId=" + loginId, {
      loginId: loginId
    }
    );
  }

  SalaryComponentList(id: any) {
    return this.httpClient.get(environment.url + "api/SalaryComponentList&id=" + id);
  }
  /////////////////////////////////////////////////////////
  createLeavingDetails(FormDetails: any,id: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createLeavingDetails&id=" +id,
      JSON.stringify(FormDetails)
    );
  } 

  getReportingPersonList(id : any) {
    return this.httpClient.get(environment.url + "api/getReportingPersonList&id="+ id );
  }
}
